import styled, { type Colors, css } from 'styled-components';
import Image, { type ImageProps } from 'next/legacy/image';
import Link from 'next/link';
import { useContext, useEffect, useRef, useState } from 'react';
import type { KeyboardEvent, ReactNode, MouseEvent } from 'react';

import { gridBase, media } from '@yoweb/ui/styles/utils';
import { useScrollHandler } from '@yoweb/ui/hooks/useScrollHandler';
import { Text } from '@yoweb/ui/components/typography';
import { Button } from '@yoweb/ui/components/buttons';
import {
  getColor,
  getDuration,
  getSize,
  getSpace,
  getZIndex,
  getRadii,
} from '@yoweb/ui/styles/utils/theme';
import { Container } from '@yoweb/ui/components/containers';
import ItemContent from './ItemContent';
import type { WithActiveState, WithDirection } from './shared';
import { Direction } from './shared';
import ChatMessage from './ChatMessage';
import { InView, StaggerElements } from '@yoweb/ui/components/AnimateIn';
import scroll from '@yoweb/utils/scroll';
import type { ButtonVariant } from '@yoweb/ui/components/buttons/Button';
import { LinkContext } from '@yoweb/ui/components/Link';
import { parseUrlToMatchEnvironment } from '@yoweb/utils/common';

type ItemImage = Pick<ImageProps, 'src' | 'alt' | 'objectPosition'>;

type VerticalScrollCarouselItem = {
  image: ItemImage;
  mobileImage?: ItemImage;
  initials?: string | null;
  label?: ReactNode;
  quote?: string | null;
  subtitle: string | ReactNode | undefined;
  title: string;
};

export type VerticalScrollCarouselProps = {
  background?: keyof Colors;
  compact?: boolean;
  dataTestId?: string;
  testIdPrefix?: string;
  ctaHref?: string;
  ctaLabel?: string;
  clickHandler?: (e: MouseEvent) => void;
  items: VerticalScrollCarouselItem[];
  buttonVariant?: ButtonVariant;
} & DataTestId;

type WithItemCount = {
  itemCount: number;
};

const imageDefaults: Partial<ImageProps> = {
  src: '',
  alt: '',
  layout: 'fill',
  objectFit: 'cover',
  objectPosition: 'center center',
  placeholder: 'blur',
};

export const VerticalScrollCarousel = ({
  background,
  compact,
  dataTestId,
  testIdPrefix,
  ctaHref,
  ctaLabel,
  clickHandler,
  items,
  buttonVariant = 'secondary',
}: VerticalScrollCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const elementRef = useRef<HTMLDivElement>(null);
  const positions = useRef<number[]>([]);
  const baseUrl = useContext(LinkContext);

  useScrollHandler(({ current }) => {
    let nextIndex = 0;

    for (let index = 0; index < positions.current.length; index++) {
      if (current.y >= positions.current[index]) {
        nextIndex = index;
      }
    }

    setActiveIndex(nextIndex);
  });

  useEffect(() => {
    const resizeHandler = () => {
      const element = elementRef.current;
      if (!element) {
        return;
      }

      const { height } = element.getBoundingClientRect();
      const y = element.offsetTop;
      const count = items.length;
      const size = height / count;
      positions.current = Array.from(
        { length: count },
        (_, index) => y + index * size - size / count - 0.5 * size,
      );
    };

    resizeHandler();

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [items.length]);

  // If section title is focused by keyboard the section is expanded
  const handleKeyPress = (event: KeyboardEvent): void => {
    if (event.key === 'Tab' && !event.shiftKey) {
      if (activeIndex === items.length - 1) {
        return;
      }
      scroll.toTop(positions.current[activeIndex + 1]);
    } else if (event.key === 'Tab' && event.shiftKey) {
      if (activeIndex === 0) {
        return;
      }

      scroll.toTop(positions.current[activeIndex - 1]);
    }
  };

  return (
    <Wrapper
      data-testid={dataTestId}
      id="vertical-scroll-carousel-wrapper"
      ref={elementRef}
      itemCount={items.length}
      background={background}
    >
      <Sticky>
        <InView>
          {(isInView) => (
            <StaggerElements isInView={isInView}>
              <StyledContainer
                data-testid="vertical-scroll-carousel"
                id="vertical-carousel-item-container"
                hasCtaButton={Boolean(ctaLabel && ctaHref)}
                fillTabletWidth
              >
                {items.map((section, index) => {
                  const isActive = activeIndex === index;
                  // eslint-disable-next-line no-nested-ternary
                  const direction: Direction = isActive
                    ? Direction.Current
                    : index < activeIndex
                      ? Direction.Above
                      : Direction.Below;

                  return (
                    <InView key={index}>
                      {(itemInView) => (
                        <Item compact={compact}>
                          <Aside isActive={isActive} aria-hidden>
                            <DesktopImageWrap direction={direction}>
                              <Image {...imageDefaults} {...section.image} />
                            </DesktopImageWrap>

                            <MobileImageWrap direction={direction}>
                              <Image
                                {...imageDefaults}
                                {...(section?.mobileImage ? section.mobileImage : section.image)}
                              />
                            </MobileImageWrap>

                            <ChatMessage
                              isInView={itemInView}
                              isActive={isActive}
                              initials={section.initials}
                              message={section.quote}
                            />

                            {section.label && <StyledLabel>{section.label}</StyledLabel>}
                          </Aside>

                          <ItemContent
                            keyboardHandler={handleKeyPress}
                            isInView={itemInView}
                            direction={direction}
                            title={section.title}
                            body={section.subtitle}
                          />
                        </Item>
                      )}
                    </InView>
                  );
                })}
                {ctaLabel && ctaHref && (
                  <ButtonWrap>
                    <Link
                      href={parseUrlToMatchEnvironment(baseUrl, ctaHref).toString()}
                      passHref
                      legacyBehavior
                    >
                      <Button
                        data-testid={`${testIdPrefix}-vertical-carousel-button`}
                        variant={buttonVariant}
                        size="lg"
                        as="a"
                        onClick={clickHandler}
                        href={ctaHref}
                        weight="bold"
                      >
                        {ctaLabel}
                      </Button>
                    </Link>
                  </ButtonWrap>
                )}
                {ctaLabel && clickHandler && !ctaHref && (
                  <ButtonWrap>
                    <Button
                      data-testid={`${testIdPrefix}-vertical-carousel-button`}
                      variant={buttonVariant}
                      size="lg"
                      as="a"
                      onClick={clickHandler}
                      weight="bold"
                    >
                      {ctaLabel}
                    </Button>
                  </ButtonWrap>
                )}
              </StyledContainer>
            </StaggerElements>
          )}
        </InView>
      </Sticky>
    </Wrapper>
  );
};

const getItemCount = ({ itemCount }: WithItemCount) => itemCount;

const Wrapper = styled.section<WithItemCount & { background?: keyof Colors }>`
  ${({ background }) =>
    background &&
    css`
      background: ${getColor(background)};
    `}

  ${media.sm`
    margin: 0 auto;
    position: relative;
    width: 100%;
  `}

  ${media.md<WithItemCount>`
    min-height: calc(60vh * ${getItemCount} + 2 * ${getSpace('large3')});
    
  `}

  ${media.lg<WithItemCount>`
    margin-bottom: ${getSpace('xl1')};
    margin-top: ${getSpace('normal1')};
  `}
`;

const StyledContainer = styled(Container)<{ hasCtaButton: boolean }>`
  ${({ hasCtaButton }) => {
    const paddingTop = hasCtaButton ? 'normal1' : 'large3';

    return css`
      ${media.lg`
        padding-top: ${getSpace(paddingTop)};
      `}
    `;
  }}
`;

const Sticky = styled.div`
  ${media.md`
    align-content: center;
    bottom: ${getSpace('large3')};
    display: grid;
    min-height: calc(60vh + 3 * ${getSpace('large3')});
    height: calc(100vh - 2 * ${getSpace('large3')});
    position: sticky;
    top: ${getSpace('large3')};
    transform: translateZ(0);
  `}
`;

const Aside = styled.div<WithActiveState>`
  grid-column: 1 / span 4;
  position: relative;
  width: 100%;

  ${media.md`
    border-radius: ${getRadii('large')};
    overflow: hidden;
  `}

  ${media.md<WithActiveState>`
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(50% + ${getSize('gridGap')} / 2);
  `}
`;

const DesktopImageWrap = styled.div<WithDirection>`
  background: ${getColor('base500')};
  display: none;
  margin: 0;
  padding-bottom: 100%;
  transition: opacity ${getDuration('interaction')}ms;
  position: relative;

  ${({ direction }) => {
    switch (direction) {
      case Direction.Above:
        return css`
          opacity: 0;
        `;
      case Direction.Current:
        return css`
          opacity: 1;
          z-index: ${getZIndex('layer')};
        `;
      case Direction.Below:
        return css`
          opacity: 0;
          z-index: ${getZIndex('above')};
        `;
    }
  }}

  ${media.md<WithDirection>`
     display: block;
  `}
`;

const MobileImageWrap = styled.div<WithDirection>`
  background: ${getColor('base500')};
  display: block;
  transition: opacity ${getDuration('interaction')}ms;
  margin: 0 -${getSize('gridGap')};
  position: relative;
  padding-bottom: 480px;

  ${media.md<WithDirection>`
    display: none;
  `}
`;

const Item = styled.div<{ compact?: boolean }>`
  ${gridBase};

  ${media.md<{ compact?: boolean }>`
    padding-bottom: ${({ compact }) => (compact ? getSpace('medium2') : getSpace('medium1'))};
  `}

  ${media.lg<{ compact?: boolean }>`
    padding-bottom: ${({ compact }) => (compact ? getSpace('medium2') : getSpace('large3'))};
  `}
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-items: start;
  margin-top: -${getSpace('normal3')};
  margin-bottom: ${getSpace('medium3')};

  ${media.md`
    margin-bottom: 0;
    margin-top: 12px;
    padding-left: calc(17.2% / 2 + 50% - ${getSize('gridGap')} / 2);
  `}

  ${media.lg`
    margin-top: -${getSpace('normal1')};
  `}
`;

const StyledLabel = styled(Text)`
  && {
    position: absolute;
    bottom: ${getSpace('normal2')};
    left: 0;
    z-index: 2;

    ${media.md`
      left: auto;
      right: ${getSpace('normal2')};
      bottom: ${getSpace('normal1')};
    `}

    ${media.lg`
      right: ${getSpace('normal4')};
      bottom: ${getSpace('normal4')};
    `}
  }
`;

export default VerticalScrollCarousel;
