import { PrismicRichText } from '@prismicio/react';
import * as prismicHelpers from '@prismicio/helpers';
import { useContext } from 'react';

import {
  defaultSerializer,
  smallPrintSerializer,
} from '@yoweb/prismic-slicemachine/utils/serializers';
import { ExplanationSection } from '@yoweb/ui/components/sections/ExplanationSection';
import { FeaturedSection } from '@yoweb/ui/components/sections/FeaturedSection';
import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import { isObjectEmpty } from '@yoweb/utils/isObjectEmpty';
import { objRemoveEmpty } from '@yoweb/utils/objRemoveEmpty';
import type { ExplanationSectionProps } from '@yoweb/ui/components/sections/ExplanationSection/ExplanationSection';
import {
  type ExplanationSectionSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
  requiredPrimaryFieldsWithBiggerItemFont,
  requiredItemsFieldsWithBiggerItemFont,
  requiredItemsFieldsFeaturedSection,
  requiredPrimaryFieldsFeaturedSection,
} from './ExplanationSectionSliceTypes';
import type { ColorType } from '@yoweb/ui/components/typography';
import { parseUrlToMatchEnvironment } from '@yoweb/utils/common';
import { LinkContext } from '@yoweb/ui/components/Link';

type ExplanationSectionSliceProps = {
  slice: ExplanationSectionSliceResponse;
} & Omit<
  ExplanationSectionProps,
  'title' | 'ctaHref' | 'ctaLabel' | 'imageContainerHeight' | 'items' | 'backgroundColor'
>;

const ExplanationSectionSlice = ({ slice, ...rest }: ExplanationSectionSliceProps) => {
  const {
    title,
    ctaHref,
    ctaLabel,
    imageContainerHeight,
    backgroundColor,
    subtitle,
    textColor = 'primary',
  } = slice.primary;
  const baseUrl = useContext(LinkContext);

  const props = {
    title,
    ctaHref,
    ctaLabel,
    imageContainerHeight,
    backgroundColor,
    textColor,
    subtitle: subtitle ? (
      <PrismicRichText field={subtitle} components={smallPrintSerializer} />
    ) : undefined,
  };

  if (slice.variation === 'featuredSection') {
    const items = !isObjectEmpty(slice.items?.[0])
      ? slice.items.map((item) => ({
          image: item.image,
          imageHref: item?.imageHref ?? null,
          imageHeight: item?.imageHeight ?? 0,
          imageWidth: item?.imageWidth ?? 0,
        }))
      : [];

    return (
      <FeaturedSection
        title={title}
        backgroundColor={backgroundColor}
        featuredItems={items}
        data-testid="featured-explanation-section"
        textColor={textColor as ColorType}
      />
    );
  }

  if (slice.variation === 'withBiggerItemFont') {
    const items = slice.items.map((item) => ({
      title: item.title,
      description: prismicHelpers.asText(item.description),
    }));
    return (
      <ExplanationSection
        {...props}
        ctaHref={ctaHref ? parseUrlToMatchEnvironment(baseUrl, ctaHref).toString() : ''}
        items={items}
        {...rest}
        data-testid="explanation-section-with-bigger-item-font"
      />
    );
  }

  const items = !isObjectEmpty(slice.items?.[0])
    ? slice.items
        // Replace all null values with undefined
        .map((item) => ({
          title: item.title,
          description: item.description ? (
            <PrismicRichText field={item.description} components={defaultSerializer} />
          ) : undefined,
          image: item.image?.url ?? '',
          imageHeight: item?.imageHeight ?? 0,
          imageWidth: item?.imageWidth ?? 0,
          imageHref: item?.imageHref ?? 0,
        }))
        // Remove empty properties from item
        .map((item) => objRemoveEmpty(item))
    : [];

  return (
    <ExplanationSection
      {...props}
      ctaHref={ctaHref ? parseUrlToMatchEnvironment(baseUrl, ctaHref).toString() : ''}
      items={items}
      {...rest}
      data-testid={`default-explanation-section`}
    />
  );
};

export default withPrismicGuardian(ExplanationSectionSlice, {
  type: 'variations',
  variations: {
    'default-slice': {
      primaryRequired: requiredPrimaryFields,
      itemsRequired: requiredItemsFields,
    },
    withBiggerItemFont: {
      primaryRequired: requiredPrimaryFieldsWithBiggerItemFont,
      itemsRequired: requiredItemsFieldsWithBiggerItemFont,
    },
    featuredSection: {
      primaryRequired: requiredPrimaryFieldsFeaturedSection,
      itemsRequired: requiredItemsFieldsFeaturedSection,
    },
  },
});
